.login-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f1f1f1;
  background: url(../../../images/login/login_bg.png) no-repeat center;
  background-size: cover;
  padding: 0 17rem;
  .logo {
    position: absolute;
    left: 5rem;
    top: 2.5rem;
  }
  .switches-container {
    width: 16rem;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: #ffffff;
    line-height: 3rem;
    border-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    box-shadow: inset 0px 3px 6px #f6f6ff, 0px 3px 6px #00000029;

    input {
      // visibility: hidden;
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
      // z-index: 5;
      opacity: 0;
      left: 0;

      &#switchTeacher {
        right: 0;
        left: auto;
      }
    }
    label {
      width: 50%;
      padding: 0;
      margin: 0;
      text-align: center;
      cursor: pointer;
      color: #4f42ff;
      font-size: 1.25rem;
    }
    .switch-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50%;
      padding: 0.15rem;
      z-index: 3;
      transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
      /* transition: transform 1s; */
    }
    .switch {
      border-radius: 3rem;
      background: #4f42ff;
      height: 100%;

      div {
        width: 100%;
        text-align: center;
        opacity: 0;
        display: block;
        color: #ffffff;
        transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
        will-change: opacity;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.25rem;
      }
    }
  }

  /* slide the switch box from right to left */
  .switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
    transform: translateX(0%);
  }

  /* slide the switch box from left to right */
  .switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
    transform: translateX(100%);
  }

  /* toggle the switch box labels - first checkbox:checked - show first switch div */
  .switches-container
    input:nth-of-type(1):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(1) {
    opacity: 1;
  }

  /* toggle the switch box labels - second checkbox:checked - show second switch div */
  .switches-container
    input:nth-of-type(2):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(2) {
    opacity: 1;
  }

  .login-header {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    background-color: #4f42ff;
    color: #ffffff;
    padding: 1.25rem;
    width: 500px;
    max-width: 100%;
    text-align: center;
    margin: auto;
    h3 {
      font-weight: 700;
      margin: 0;
      text-transform: uppercase;
      position: relative;
      span {
        position: absolute;
        left: 0;
        padding: 0 1rem;
        cursor: pointer;
      }
    }
    p {
      padding: 1.5rem 4rem 0;
      margin: 0;
    }
  }
  .login-form {
    width: 500px;
    max-width: 100%;
    margin: auto;
    padding: 3rem 4rem 4rem;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background-color: #ffffff;
    box-shadow: inset 0px 3px 6px #f6f6ff, 0px 3px 6px #00000029;
    position: relative;

    input {
      border: 1px solid #4F42FF;
    }

    input[name="password"] {
      padding-right: 2.5rem;
    }

    .icon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      color: #4F42FF;
    }
    .btn-link {
      padding: 0;
      color: #3a2cf3;
      text-decoration: none;
      &:focus,
      &:hover,
      &:active {
        box-shadow: none;
      }
    }

    .login-btn {
      height: 3rem;
    }
    .btn-light {
      border-color: #d3d4d5;
      &:hover,&:active,&:focus {
        background-color: #ffffff;
      }
    }

    &:after {
      content: "";
      background: url(../../../images/login/login_character.png) no-repeat;
      background-size: 100% auto;
      position: absolute;
      right: -190px;
      z-index: 5;
      width: 250px;
      height: 440px;
      bottom: 27px;
    }
  }
}
