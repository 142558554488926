.school-dashboard-container {
  .card {
    &.my-teachers {
      top: 1rem;
      bottom: inherit;
      width: 33%;
      right: 0;
      left: inherit;
      height: 98%;
    }
    &.leaderboard {
      left: 0;
      width: 66%;
    }
    &.my-quests {
      top: inherit;
      bottom: 0;
    }

    // My students
    &.student-profile {
      .personal-info {
        text-align: center;
        margin: 0 0 1rem;
        .profile-pic {
          margin: 0 0 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            min-height: 100px;
            max-width: 100%;
          }
        }
      }
      .quest-info {
        width: 80%;
        max-width: 100%;
        margin: 0 auto;
        .list-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem;
        }
      }
    }

    &.all-teachers {
      .activity-list {
        height: calc(100% - 55px);
        .list-item {
          background-color: #f5f5f5;
          padding: 0.75rem;
          border-radius: 0.5rem;
          height: auto;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 0.75rem;
          .teacher {
            p {
              color: #767676;
              font-size: 12px;
              line-height: 1.3;
              margin: 0;
            }
          }
          .grade {
            flex: none;
            text-align: center;
            width: 86px;
            .grade-list {
              padding: 0;
              margin: 0;
              display: flex;
              list-style: none;
              flex-direction: row;
              column-gap: 0.5rem;
              flex-wrap: wrap;
              row-gap: 0.25rem;
              justify-content: center;
              .grade-list-item {
                span {
                  background-color: #cddefe;
                  min-width: 23px;
                  min-height: 23px;
                  border-radius: 50%;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  padding: 1px;
                  font-size: 10px;
                  color: #1d5cd4;
                }
              }
            }
          }
        }
      }
    }

    // School leaderboard
    &.school-leaderboard {
      .leaderboard-list {
        display: flex;
        column-gap: 1.5rem;
        height: 100%;
        .leaderboard-list-item {
          position: static;
          width: 33%;
          position: relative;
          overflow: hidden;

          &:before {
            content: "";
            width: 100%;
            height: 8px;
            position: absolute;
            bottom: 0;
            left: 0;
          }
          &:nth-child(1) {
            &:before {
              background-color: #C12424;
            }
            .rank {
              .score {
                color: #C12424;
              }
            }
          }
          &:nth-child(2) {
            &:before {
              background-color: #E87A0C;
            }
            .rank {
              .score {
                color: #E87A0C;
              }
            }
          }
          &:nth-child(3) {
            &:before {
              background-color: #E8C516;
            }
            .rank {
              .score {
                color: #E8C516;
              }
            }
          }

          .rank-with-number {
            position: absolute;
            bottom: 0;
            right: 2rem;
            width: 18%;
            text-align: right;
            display: none;

            &:nth-child(1){
              width: 12%;
            }

            img {
              max-width: 100%;
            }
          }
          .card-body {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
          }

          .rank {
            display: flex;
            align-items: center;
            column-gap: 1rem;
            .medal {
              display: none;
              img {
                max-height: 44px;
              }
            }
            .score {
              font-size: 4rem;
              font-weight: 700;
              line-height: 1.1;
            }
          }
          h6 {
            color: #b2b2b2;
            margin-bottom: 2px;
          }
          p {
            font-weight: 600;
            margin: 0;
          }
        }
      }
    }
  }
}
