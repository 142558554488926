.dashboard-container {
  height: 100% !important;
  position: relative;
  .card {
    position: absolute;
    border-radius: 0.75rem;
    border-color: #adadad;
    transition: all 0.3s ease;

    .card-title {
      text-transform: uppercase;
      font-weight: 600;
      display: flex;
      align-items: center;
      padding: 1.5rem 1.5rem 0;
      margin: 0;
      position: relative;
      span {
        border-bottom: 1px solid #adadad;
        padding-bottom: 2px;
      }
      .expand {
        position: absolute;
        right: -1px;
        top: -1px;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 0.75rem;
        background-color: #adadad;
        border-bottom-left-radius: 0.75rem;
        color: #ffffff;
        cursor: pointer;
        z-index: 5;
        transition: all 0.2s ease;
        &:hover,
        &:focus {
          background-color: #4f42ff;
          transform: scale(1.1);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        }
      }
      span {
        margin-right: 1rem;
      }
      .btn {
        margin-left: 1rem;
      }
      .dropdown-toggle {
        color: #070707;
        min-width: 120px;
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:after {
          margin-left: 0.5em;
          vertical-align: 0.1rem;
        }
        &:hover,
        &:focus {
          background-color: transparent;
          box-shadow: none;
        }
      }
      .dropdown-item {
        text-transform: capitalize;
      }
      .filter {
        margin-left: auto;
        .dropdown-toggle {
          min-width: 100px;
        }
        .dropdown-item {
          display: flex;
          align-items: center;
          input {
            margin-top: 2px;
          }
          span {
            border: 0 none;
            padding: 0 0 0 0.25rem;
          }
        }
      }
    }
    .card-body {
      color: #070707;
      padding: 1.5rem;
      height: 100%;
      overflow: hidden;
      .no-data {
        height: inherit;
        display: flex;
        justify-content: center;
        font-size: 26px;
        color: gray;
        align-items: center;
      }
      .table-responsive {
        height: 600px;
        max-height: 100%;
      }
      .report-table {
        min-height: 610px;
      }
      .activity-list,
      .active-quests-list {
        margin: 0;
        padding: 0;
        list-style: none;
        height: 100%;
        overflow: auto;
        .feedback-list-header {
          font-size: 16px;
          font-weight: 500;
          color: #6e6e6e;
        }
        .list-item {
          // height: 73px;
          display: flex;
          align-items: flex-start;
          column-gap: 1rem;
          padding: 0.5rem 0;
          margin-bottom: 0.25rem;
          margin-right: 1rem;
          .profile-img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .notes {
            p {
              margin: 0;
            }
          }
        }
      }
      .active-quests-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        max-height: 100%;
        .list-item {
          align-items: center;
          border: 1px solid #adadad;
          border-radius: 12px;
          width: 100%;
          margin: 0 1rem 1rem 0;
          padding: 0.75rem;

          .quest-banner {
            max-width: 180px;
            max-height: 180px;
            border-radius: 0.75rem;
            .report-icon {
              font-size: 2.25rem;
              color: #707070;
            }
          }
          .quest-group-name {
            font-size: 1rem;
            font-weight: 500;
            margin: 0;
          }
          .students-count {
            font-size: 0.875rem;
            margin: 0;
          }
          // .progress {
          //   width: 100%;
          //   height: 6px;
          // }
          &.new-item {
            height: 73px;
            border: 2px solid #3a2cf3;
            .quest-banner {
              .report-icon {
                color: #3a2cf3;
              }
            }
          }
        }
      }
    }

    .homework-table {
      //   height: 100%;
      min-width: 700px;
    }

    // My Students

    &.my-quests,
    &.my-students,
    &.my-report,
    &.my-teachers {
      left: 0;
      top: 1rem;
      width: 66%;
      height: 48%;

      .filter {
        margin-right: 1.5rem;
      }

      .status-filter {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }

      &.expand {
        width: 100%;
        height: 100%;
        z-index: 10;
      }

      //   My quest list
      &.my-quests-list,
      &.my-students-list,
      &.my-report-list,
      &.my-teachers-list {
        .table-responsive {
          max-height: 90%;
        }
        &.expand {
          top: 5rem;
          height: calc(100% - 80px);
          box-shadow: 0px 3px 6px #00000029;
          border: 0 none;
        }
        .list-header {
          position: absolute;
          top: -5rem;
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          font-size: 1.125rem;
          .breadcrumb {
            margin-bottom: 0;
            .breadcrumb-item {
              a {
                color: #6c757d;
                text-decoration: none;
                font-weight: 500;
                svg {
                  font-size: 1.5rem;
                  margin-right: 0.25rem;
                  margin-top: -0.25rem;
                }
              }
              &.active {
                color: #6156ff;
                font-weight: 600;
              }
              & + .breadcrumb-item::before {
                content: ">";
              }
            }
          }

          .search {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            column-gap: 1rem;
            .btn {
              flex: none;
            }
            .input-group {
              // display: none;
              .form-control {
                border: 1px solid #4f42ff;
                border-right: #ffffff;
                width: 100px;
                transition: width 0.2s ease-in;

                &:focus {
                  width: 200px;
                }
              }
              .input-group-text {
                border: 1px solid #4f42ff;
                background-color: #ffffff;
                color: #4f42ff;
                font-size: 1.125rem;
              }
            }
          }
          .dropdown-toggle {
            color: #070707;
            min-width: 120px;
            padding-left: 1rem;
            padding-right: 1rem;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:after {
              margin-left: 0.5em;
              vertical-align: 0.1rem;
            }
            &:hover,
            &:focus {
              background-color: transparent;
              box-shadow: none;
            }
          }
          .dropdown-item {
            text-transform: capitalize;
          }
          .filter {
            margin-left: auto;
            .dropdown-toggle {
              min-width: 100px;
            }
            .dropdown-item {
              display: flex;
              align-items: center;
              input {
                margin-top: 2px;
              }
              span {
                border: 0 none;
                padding: 0 0 0 0.25rem;
              }
            }
          }
          .filter-wrapper {
            margin-left: auto;
            display: flex;
            column-gap: 1rem;
            // margin-right: 10rem;
          }
        }
        .card-body {
          .search {
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;
            column-gap: 1rem;
            position: absolute;
            top: -59px;
            right: 165px;
            z-index: 2;
            .btn {
              flex: none;
            }
            .input-group {
              .form-control {
                border: 1px solid #4f42ff;
                border-right: #ffffff;
                width: 100px;
                transition: width 0.2s ease-in;

                &:focus {
                  width: 200px;
                }
              }
              .input-group-text {
                border: 1px solid #4f42ff;
                background-color: #ffffff;
                color: #4f42ff;
                font-size: 1.125rem;
              }
            }
          }
        }
      }
    }

    // Activity Feed
    &.activity-feed {
      right: 0;
      bottom: 0;
      width: 33%;
      height: 48%;
    }

    // Leaderboard
    &.leaderboard {
      right: 0;
      top: 1rem;
      width: 33%;
      height: 48%;
      .homework-table {
        min-width: auto;
      }

      // &.expand {
      //   width: 100%;
      //   height: 100%;
      //   z-index: 2;
      //   bottom: -1rem;
      // }
    }

    // My students
    &.my-students {
      left: 0;
      bottom: 0;
      width: 26%;
      height: 48%;
      top: auto;

      .filter {
        margin-right: 1.5rem;
      }

      &.expand {
        width: 100%;
        height: 100%;
        z-index: 2;
        bottom: -1rem;
      }

      .activity-list {
        .list-item {
          .profile-img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }

    // My report
    &.my-report {
      left: 27%;
      bottom: 0;
      width: 39%;
      height: 48%;
      top: auto;

      &.expand {
        width: 100%;
        height: 100%;
        z-index: 2;
        bottom: -1rem;
        left: 0;
      }

      .filter {
        margin-right: 1.5rem;
      }
    }
  }
}
