$transparent: transparent;
$white: #ffffff;
$black: #000000;
$lightBlack: #131f33;
$brown: #ffffff6e;
$checkbox: #0000001f;
$green: #3bff96;
$light-green: #17e977;
$lightgreen: #0ccb63;
$dark-green: #01c95d;
$skyblueBg: #0d99c4;
$blue: #53d6ff;
$violet: #9477df;
$light-violet: #e3ccff;
$dark-violet: #9a77ee;
$badgeViolet: #e3c6ff;
$yellow: #ffdb82;
$darkyellow: #fcb611;
$lightRed: #ff8e8e;
$skyblue: #8de2ff;
$greenMenu: #cbff94;
$sampleBackground: #041026;
$checkboxbg: #efefef1a;
$light-blue: #4dd5ff;
$dark-blue: #53d6ff;
$border: #a9aeb4;
$red: red;
$breadcrumbColor: #cecece;
$sortBtn: #06152b;
$dropdownBg: #252e41;
$menuBg: #132133;
.red {
  color: red;
}
