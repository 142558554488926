textarea {
  resize: none;
}

.fields {
    padding: 1.5rem 0;
    display: flex;
    column-gap: 1rem;
    flex-wrap: wrap;
    row-gap: 1rem;   

  .badge {
    border: 1px solid #4f42ff;
    color: #4f42ff !important;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    .close {
      padding-left: 0.75rem;
      font-size: 1.125rem;
      line-height: 1;
      cursor: pointer;
    }
  }
}

.dropdown-menu {
  max-height: 250px;
  overflow: auto;
}
