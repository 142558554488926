.parent-dashboard-container {
  .card {
    &.my-students {
      top: 1rem;
      bottom: inherit;
      width: 26%;
    }
    &.leaderboard {
      left: 27%;
      width: 39%;
    }
    &.activity-feed {
      bottom: inherit;
      top: 1rem;
    }
    &.my-quests {
      top: inherit;
      bottom: 0;
    }

    &.active-quests {
      right: 0;
      left: inherit;
      width: 33%;
      bottom: 0;
      height: 48%;
    }

    &.active-quests.parent {
      .active-quests-list {
        .list-item {
          height: auto;
          border: 0 none;
          margin: 0 1rem 0 0;
          padding: 0.75rem 0;
          border-bottom: 1px solid #e1e1e1;
          border-radius: 0;
          .quest-banner {
            width: 60px;
            height: 60px;
            border-radius: 0.75rem;
            flex: none;
            img {
              max-height: 60px;
            }
          }
          .quest-group-name {
            font-size: 0.875rem;
            margin-bottom: 2px;
            font-weight: 400;
          }

          .quest-status {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            .progress {
              height: 8px;
            }
            span {
              flex: none;
              font-size: 0.875rem;
            }
          }
          .quest-info {
            width: 100%;
          }
        }
      }
    }

    // My students
    &.student-profile {
      .personal-info {
        text-align: center;
        margin: 0 0 1rem;
        .profile-pic {
          margin: 0 0 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            min-height: 100px;
            max-width: 100%;
          }
        }
      }
      .quest-info {
        width: 80%;
        max-width: 100%;
        margin: 0 auto;
        .list-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
