.assign-questions {
  color: #070707;
  height: calc(100vh - 72px);
  .back-header {
    display: flex;

    .back {
      margin: auto;
      margin-right: 12px;
      // margin-bottom: 0.5rem;
      line-height: 1;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0 1rem 0;
    .quest-title {
      margin-bottom: 0;
    }

    .dropdown-toggle {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      font-weight: 500;
    }
  }
  .hint-text {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
  }
  // Quest group list column
  .quest-group-list-col {
    flex: none;
    width: 350px;
  }

  // Graphics column
  .graphics-col {
    background-color: #ffffff;
    height: calc(100vh - 72px);
    padding: 5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .save-assign-quest {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    .isometric {
      position: relative;
      width: 100%;
      height: 100%;
      .img-fluid {
        max-height: 98%;
        max-width: 98%;
      }
      .char {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .img-fluid {
          max-width: 80%;
          max-height: 80%;
        }

        &.char-1 {
          left: 48%;
          top: 65%;
        }
        &.char-2 {
          left: 25%;
          top: 51%;
        }
        &.char-3 {
          left: 41%;
          top: 30%;
        }
        &.char-4 {
          left: 66%;
          top: 34%;
        }
        &.char-5 {
          left: 84%;
          top: 30%;
        }
        &.unassigned {
          img {
            filter: grayscale(1);
            -webkit-animation: expand 1s linear alternate infinite;
            animation: expand 1s linear alternate infinite;
            position: relative;
          }
        }
        &.assigned {
          img {
            filter: grayscale(0);
          }
        }
        &.available {
          background-color: rgba(255, 255, 255, 0.7);
          border: 1px dashed #333333;
        }

        @-webkit-keyframes expand {
          0% {
            top: 0px;
          }
          100% {
            top: 15px;
          }
        }
        @keyframes expand {
          0% {
            top: 0px;
          }
          100% {
            top: 15px;
          }
        }

        .unassign {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.7);
          color: #ffffff;
          font-size: 2rem;
          border-radius: 1rem;
          transition: all 0.2s ease;
        }

        &:hover {
          .unassign {
            opacity: 1;
            visibility: visible;
            .icons {
              color: red;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .filter {
    margin-left: 1.5rem;

    .dropdown-toggle {
      min-width: 100px;
    }

    .dropdown-item {
      display: flex;
      align-items: center;

      input {
        margin-top: 2px;
      }

      span {
        border: 0 none;
        padding: 0 0 0 0.25rem;
      }
    }
  }

  // guest group list

  .quest-group-list {
    padding-right: 1.5rem;
    height: calc(100vh - 260px);
    overflow: auto;
    padding-top: 1rem;
    // overflow: auto;

    .react-draggable {
      z-index: 1;
    }
    .card {
      margin-bottom: 1.5rem;
      border-color: #bdbdbd;
      box-shadow: 0px 3px 6px #00000029;
      cursor: move;

      &.unassigned {
        // z-index: 1;
        box-shadow: 0px 3px 6px #0000005c;
        border-color: transparent;
      }
      &.assigned {
        cursor: not-allowed;
        // z-index: 1;
        box-shadow: 0px 3px 6px #0000005c;
        border-color: transparent;

        &::before {
          content: "";
          width: 50px;
          height: 50px;
          position: absolute;
          right: -15px;
          top: -15px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          box-shadow: 0px 3px 6px #0000005c;
        }
        .card-title {
          padding-right: 1rem;
        }
      }
      &.magnet {
        background-color: #7ad7ff;
        &:before {
          background: #7ad7ff url(../../images/characters/magnet.png) no-repeat;
          background-position: center;
          background-size: 75% auto;
        }
      }
      &.spaceboy {
        background-color: #82b329;
        &:before {
          background: #82b329 url(../../images/characters/spaceboy.png)
            no-repeat;
          background-position: center;
          background-size: 65% auto;
        }
      }
      &.robot {
        background-color: #f89a39;
        &:before {
          background: #f89a39 url(../../images/characters/robot.png) no-repeat;
          background-position: center;
          background-size: 75% auto;
        }
      }
      &.lightbulb {
        background-color: #ff6cb0;
        &:before {
          background: #ff6cb0 url(../../images/characters/lightbulb.png)
            no-repeat;
          background-position: center;
          background-size: 75% auto;
        }
      }
      &.happy {
        background-color: #edc71f;
        &:before {
          background: #edc71f url(../../images/characters/happy.png) no-repeat;
          background-position: center;
          background-size: 75% auto;
        }
      }
    }
  }
}
