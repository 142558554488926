:root {
  --bs-link-color: #4f42ff;
  --bs-link-hover-color: #4f42ff;
}

.btn {
  --bs-btn-padding-x: 1.5rem;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4f42ff;
  --bs-btn-border-color: #4f42ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4f42ff;
  --bs-btn-hover-border-color: #4f42ff;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4f42ff;
  --bs-btn-active-border-color: #4f42ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #8a8a8a;
  --bs-btn-disabled-border-color: #8a8a8a;
}
.btn-outline-primary {
  --bs-btn-color: #4f42ff;
  --bs-btn-border-color: #4f42ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4f42ff;
  --bs-btn-hover-border-color: #4f42ff;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4f42ff;
  --bs-btn-active-border-color: #4f42ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #4f42ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4f42ff;
  --bs-gradient: none;
}
.btn-dashed {
  border-style: dashed;
  --bs-btn-color: #707070;
  --bs-btn-border-color: #707070;
  --bs-btn-hover-color: #4f42ff;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #4f42ff;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #4f42ff;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #4f42ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #4f42ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4f42ff;
  --bs-gradient: none;
}

.btn {
  // min-width: 100px;
}
