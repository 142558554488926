.saved-report {
    padding: 0 1rem 1rem;
    height: 90%;
    overflow: auto;
    color: #070707;

    h4 {
        margin: 0 0 1.5rem;
    }
  .report-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 1rem;
    flex-wrap: wrap;
    .list-item {
      border: 1px solid #8a8a8a;
      padding: 1rem;
      border-radius: 0.5rem;
      width: 31%;
      position: relative;
      &:hover {
          border:1px solid  #3A2CF3;
          cursor: pointer;
          color: #3A2CF3;
      }
      .grade {
        font-weight: 400;
      }
      .action {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        display: flex;
        column-gap: 5px;
        .icon {
            width: 26px;
            height: 26px;
            border: 1px solid rgba(138, 138, 138, 0.5);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            color: #8A8A8A;
            cursor: pointer;

            &:hover {
                background-color: #e9e9e9;
            }
        }
      }
    }
  }
}
