@media (max-width: 1600px) {
  // Create questions
  .create-questions {
    .questions {
      .tab-content {
        padding: 0 0 0 5rem;
      }
    }
  }
}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
  html {
    font-size: 95%;
  }

  .main-container {
    padding: 4.5rem 2.5rem 2rem !important;
  }
  .header {
    padding: 0.75rem 2rem;
  }
  .dashboard-container {
    .card {
      .card-title {
        .btn {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
    }
  }

  // Create questions
  .mcq-quest .answer {
    padding: 2rem 0 0 3rem;
  }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  html {
    font-size: 90%;
  }
  .dashboard-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    // column-gap: 1.5rem;
    .card {
      position: static;
      margin-top: 1.5rem;

      &.my-quests {
        width: 100%;
      }
      &.leaderboard {
        width: 49%;
        &.school-leaderboard {
          width: 100%;
        }
      }
      &.activity-feed {
        width: 49%;
      }
      &.my-students {
        width: 49%;
      }
      &.my-report {
        width: 49%;
      }
      &.active-quests {
        width: 49%;
      }

      &.my-quests-list,
        &.my-students-list,
        &.my-report-list,
        &.my-teachers-list {
        position: relative;
        margin-top: 0;
      }
      &.my-teachers {
        width: 100%;
        height: 60%;
      }
    }
  }

  // Assign questions
  .assign-questions {
    .quest-group-list-col {
      width: 100%;
    }
    .graphics-col {
      height: auto;
      padding: 3rem 1rem;
      .isometric {
        .char {
          width: 12%;
        }
      }
    }
    .quest-group-list {
      height: 150px;
      display: flex;
      flex-direction: row;
      column-gap: 2rem;
      .card {
        width: 300px;
        margin-bottom: 1rem;
        flex: none;
      }
    }
  }
  .create-questions {
    .current-quest {
      right: 0;
      min-height: auto;
      .empty-quest-list {
        position: static;
        margin: 2rem 0;
      }
    }
  }

  .mcq-quest {
    &.arr-in-order-image {
      .answer {
        .form-group {
          width: 12vw;
          height: 12vw;
        }
      }
    }
  }
  .onload-anim {
    .anim-box {
      left: 60%;
      width: 50%;
    }
  }
  // Reports
  .saved-report {
    .report-list {
      .list-item {
        width: 48%;
      }
    }
  }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .login-container {
    padding: 0 3rem;
    justify-content: center;
    .login-form {
      &:after {
        right: -114px;
        width: 150px;
        height: 270px;
        bottom: 120px;
      }
    }
  }

  .mcq-quest {
    // Matching quest
    &.matching-quest {
      .answer {
        .row {
          margin-bottom: 2rem;
          padding: 0.5rem 0;

          .icon {
            line-height: 1;
            .icon-arrow {
              margin-top: -1.5rem;
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    // Arrange in order
    &.arr-in-order-image {
      .answer {
        flex-wrap: wrap;
        .form-group {
          width: 48%;
          height: 100px;
        }
      }
    }
  }

  .dashboard-container {
    .card {
      &.my-quests,
      &.my-students,
      &.my-report,
      &.my-teachers {
        &.my-quests-list,
        &.my-students-list,
        &.my-report-list,
        &.my-teachers-list {
          &.expand {
            top: 7rem;
            height: calc(100% - 100px);
          }
          .list-header {
            flex-wrap: wrap;
            top: -7rem;
            height: 7rem;
            padding: 0.75rem 0;
            nav {
              width: 100%;
              flex: none;
            }
            .search {
              .input-group {
                .form-control {
                  &:focus {
                    width: 100px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //
  .list-header nav {
    width: 100%;
    flex: none;
  }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .main-container {
    padding: 4.5rem 1.5rem 2rem !important;
  }
  .header {
    padding: 0.75rem 1rem;
  }
  .login-container {
    padding: 0 1rem;
    .logo {
      position: absolute;
      left: 2rem;
      top: 1.5rem;
    }
    .login-header {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      padding: 1rem;
      max-width: 80%;
      margin: auto;
    }
    .login-form {
      max-width: 80%;
      padding: 2rem 2rem 2rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;

      &:after {
        right: -76px;
        width: 100px;
        height: 215px;
        bottom: 50px;
      }
    }
  }

  .dashboard-container {
    // height: auto !important;
    .card {
      // max-height: 400px;
      // min-height: 300px;
      &.my-quests,
      &.my-students,
      &.my-report,
      &.my-teachers {
        width: 100%;
        .card-title {
          flex-wrap: wrap;
          span {
            width: 100%;
            margin-bottom: 1rem;
            border: 0 none;
          }
          .btn {
            margin-left: 0;
            margin-right: 0.5rem;
          }
          .filter {
            margin-right: 0;
            .dropdown-item span {
              margin: 0;
            }
          }
        }
        &.my-quests-list,
        &.my-students-list,
        &.my-report-list,
        &.my-teachers-list {
          &.expand {
            top: 11rem;
            height: calc(100% - 160px);
          }
          .list-header {
            top: -11rem;
            height: 11rem;
            nav {
              width: 100%;
              flex: none;
            }
            .search {
              width: 100%;
              .input-group {
                .form-control {
                  &:focus {
                    width: 100px;
                  }
                }
              }
            }
            .filter-wrapper {
              width: 100%;
              .filter {
                margin-right: auto;
                width: 50%;
                margin-left: initial;
                .btn {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      &.leaderboard {
        width: 100%;
      }
      &.activity-feed {
        width: 100%;
      }
      &.my-students {
        width: 100%;
      }
      &.my-report {
        width: 100%;
        margin-bottom: 2rem;
      }
      &.active-quests {
        width: 100%;
      }
    }
  }
  .create-questions {
    .questions {
      .tab-content {
        padding: 0 0 0 3rem;
      }
      .quest-nav {
        .nav-item {
          .nav-link {
            width: 100px;
            .quest-type {
              line-height: 1.2;
            }
          }
        }
      }
    }
  }

  .mcq-quest {
    .answer {
      padding: 2rem 0 0 1rem;
    }
  }
  // Reports
  .saved-report {
    .report-list {
      .list-item {
        width: 98%;
      }
    }
  }
}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .login-container {
    justify-content: inherit;
    .switches-container {
      width: 58%;
      line-height: 2.5rem;
      border-radius: 2rem;

      label {
        font-size: 1rem;
      }
      .switch div {
        font-size: 1rem;
      }
    }
    .login-card {
      width: 100%;
    }
    .login-header {
      max-width: 92%;
      h3 {
        font-size: 1.3rem;
      }
    }
    .login-form {
      width: 92%;
      max-width: 92%;
      &:after {
        display: none;
      }
    }
  }
  .dashboard-container {
    .card {
      &.my-quests {
        .card-title {
          .btn {
            width: 48%;
            margin-right: 2%;
          }
          .filter {
            margin-left: inherit;
            width: 50%;
            &.status-filter {
              order: 4;
            }
            .btn {
              width: 96%;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
  .create-questions {
    .quest-header {
      padding: 2rem 0 2rem 0;
      line-height: 1;
    }
    .back-header {
      padding-right: 1rem;
    }
    .questions {
      flex-direction: column;
      .quest-nav {
        flex-direction: row !important;
        display: flex;
        column-gap: 0.5rem;
        .nav-item {
          .nav-link {
            width: 100px;
            height: 75px;
            &.active,
            &:hover {
              transform: none;
            }
          }
        }
      }
      .tab-content {
        padding: 2rem 0 0;
      }
    }
  }

  .onload-anim {
    .anim-box {
      left: 50%;
      width: 75%;
      top: 36rem;
      flex-direction: column;
      .anim-icon {
        transform: rotate(90deg);
        right: 0;
        // bottom: -50px;
      }
    }
  }
  @keyframes mymove {
    from {
      bottom: 20px;
    }
    to {
      bottom: 40px;
    }
  }
}

@media (max-width: 400px) {
  .create-questions {
    .questions {
      .quest-nav {
        .nav-item {
          width: 48%;
          .nav-link {
            width: 100%;
          }
        }
      }
    }
  }
  .onload-anim .anim-box {
    top: 40rem;
  }
}
