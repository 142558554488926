.create-questions {
  color: #070707;
  .quest-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem 2rem 0;
  }

  .back-header {
    display: flex;

    .back {
      margin: auto;
      margin-right: 12px;
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
  .questions {
    display: flex;
    flex-direction: row;
    padding-bottom: 4rem;
    input.form-control, textarea.form-control {
      border: 1px solid #8A8A8A;
    }
    .quest-heading {
      font-size: 1.125rem;
    }

    .quest-nav {
      &.onload-nav {
        position: relative;
        z-index: 11;
        background-color: #ffffff;
        padding: 1rem;
        border-radius: 0.5rem;
        .icons {
          // display: none !important;
        }
        .nav-item {
          .nav-link {
            &:hover {
              // transform: none;
            }
            .icons.two {
              .icon {
                background-color: #ffffff !important;
              }
            }
          }
        }
      }
      flex: none;
      .nav-item {
        margin-bottom: 1.5rem;
        &:last-child {
          margin-bottom: 0;
        }
        .nav-link {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1px solid #8a8a8a;
          color: #070707;
          font-size: 14px;
          transition: all 0.2s ease;
          position: relative;
          text-align: center;

          // icons
          .icons {
            position: absolute;
            right: -0.5rem;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
            opacity: 0;
            transition: all 0.3s ease;
            padding-left: 0.5rem;

            .icon {
              width: 1.125rem;
              height: 1.125rem;
              line-height: 1;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #00d664;
              background-color: #00d664;
              color: #ffffff;
              border-radius: 50%;

              .icon-arrow {
                display: none;
              }
            }

            &.two {
              display: flex;
              flex-direction: column;
              row-gap: 5px;

              .icon {
                border-radius: 0.25rem;
                padding: 2px;
                width: 1.375rem;
                height: 1.375rem;

                &:hover {
                  background-color: #9b5de5;
                  color: #ffffff;
                }
              }
            }
          }

          &:hover,
          &:focus,
          &:active {
            background-color: #ffffff;
            color: #070707;
          }

          &:hover {
            transform: scale(1.1);
            .icons {
              opacity: 1;
              padding-left: 1.5rem;
              right: -1.5rem;
            }
          }

          &.active {
            // border-width: 2px;
            background-color: #ffffff;
            color: #070707;
            transform: scale(1.1);
            border-width: 2px;
            .icons {
              opacity: 1;
              padding-left: 1.5rem;
              right: -1.5rem;
              .icon {
                .icon-arrow {
                  display: block;
                }
                .icon-plus {
                  display: none;
                }
              }
            }
          }
          // MCQ
          &.mcq {
            .icons {
              .icon {
                border: 1px solid #00d664;
                background-color: #00d664;
              }
            }
            &:hover {
              border-color: #00d664;
            }
            &.active {
              border-color: #00d664;
            }
          }
          // true false
          &.true-false {
            .icons {
              .icon {
                border: 1px solid #f15bb5;
                background-color: #f15bb5;
              }
            }
            &:hover {
              border-color: #f15bb5;
            }
            &.active {
              border-color: #f15bb5;
            }
          }
          // Fill in the blanks
          &.fill-in-the-blanks {
            .icons {
              .icon {
                border: 1px solid #00bbf9;
                background-color: #00bbf9;
              }
            }
            &:hover {
              border-color: #00bbf9;
            }
            &.active {
              border-color: #00bbf9;
            }
          }
          // open question
          &.open-quest {
            .icons {
              .icon {
                border: 1px solid #ff8001;
                background-color: #ff8001;
              }
            }
            &:hover {
              border-color: #ff8001;
            }
            &.active {
              border-color: #ff8001;
            }
          }
          // Arrange in order
          &.arrange-in-order {
            .icons {
              .icon {
                border: 1px solid #9b5de5;
                background-color: #9b5de5;
                background-color: transparent;
                color: #9b5de5;
              }
            }
            &:hover {
              border-color: #9b5de5;
              .icons {
                padding-left: 1.75rem;
                right: -1.75rem;
              }
            }
            &.active {
              border-color: #9b5de5;
              .icons {
                padding-left: 1.75rem;
                right: -1.75rem;
              }
            }
          }
          // Matching
          &.matching {
            .icons {
              .icon {
                border: 1px solid #ff2525;
                background-color: #ff2525;
              }
            }
            &:hover {
              border-color: #ff2525;
            }
            &.active {
              border-color: #ff2525;
            }
          }
        }
      }
    }
    .tab-content {
      width: 100%;
      padding: 0 6rem;
    }
  }

  //   Question list
  .current-quest {
    background-color: #f2f2f2;
    min-height: calc(100vh - 72px);
    padding: 5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    text-align: center;
    right: -3.7rem;
    position: relative;

    .quest-list-header {
      text-align: center;
    }
    .empty-quest-list {
      position: absolute;
      top: 42%;
    }

    .question-list {
      counter-reset: number;
      .list-item {
        list-style: none;
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
        counter-increment: number;
        &::before {
          content: counter(number) ".";
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.5em;
        }
        .card {
          cursor: pointer;
          flex-direction: row;
          align-items: center;
          column-gap: 0.5rem;
          font-size: 0.875rem;
          padding: 0.5rem;
          min-width: 350px;
          max-width: 350px;
          transition: all .2s ease;
          border-width: 2px;
          .quest-type-img {
            min-width: 45px;
            img {
              max-height: 22px;
            }
          }
          .quest-type {
            text-align: left;
            line-height: 1.4;
          }
          &:hover {
            // background-color: #cccccc;
            transform: scale(1.02);
          }
        }
        .draggable {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0.25rem;
          border-radius: 0.375rem;
          background-color: #ffffff;
          font-size: 1.125rem;
          color: #939393;
          border: 1px solid #939393;
        }

        &.mcq {
          .card {
            border-color: #00d664;
          }
        }
        &.true-false {
          .card {
            border-color: #f15bb5;
          }
        }
        &.fill-in-the-blanks {
          .card {
            border-color: #00bbf9;
          }
        }
        &.open-quest {
          .card {
            border-color: #ff2525;
          }
        }
        &.match-the-following-pair {
          .card {
            border-color: #ff8001;
          }
        }
        &.arrange-in-order {
          .card {
            border-color: #9b5de5;
          }
        }
      }
    }
    .btn-block {
          margin-block-start: auto;
    }
  }
}

.mcq-quest {
  min-height: 508px;
  .answer {
    padding: 2rem 0 0 7rem;
    counter-reset: listStyle;
    .input-group {
      counter-increment: listStyle;
      &::before {
        content: counter(listStyle, lower-alpha) ".";
        width: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .form-control {
        border-radius: 0.375rem !important;
      }
      .input-group-text {
        margin-left: 1rem !important;
        border-radius: 0.375rem !important;
        padding: 0;
        background-color: transparent;
        border: 0 none;
        .form-check-input {
          width: 36px;
          height: 36px;
          margin-top: 0;
          border-radius: 0.375rem;
          &:checked {
            background-color: #4f42ff;
            border-color: #4f42ff;
          }
        }
      }
      .mcq-checbox {
        margin-right: 0;
        .form-check-input {
          width: 36px;
          height: 36px;
          margin-left: 20px;
          margin-top: 1px;
          border: 1px solid #8A8A8A;
        }
      }
      .error-msg {
        flex: none;
        display: block;
        width: 100%;
        padding-left: 2rem;
      }
    }
  }

  &.arr-in-order-image {
    .answer {
      display: flex;
      flex-direction: row;
      column-gap: 1vw;
      align-self: stretch;
      align-items: center;
      .image-preview {
        width: 7vw;
        height: 7vw;
        color: #707070;
        font-size: 0.875rem;
        border: 1px dashed #707070;
        border-radius: 0.25rem;
        position: relative;
        cursor: pointer;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover,
        &:focus {
          border: 1px solid #4f42ff;
        }
        img {
          object-fit: contain;
          max-width: 100%;
        }
        span {
          position: absolute;
          top: -12px;
          color: #4a4a4a;
          right: -10px;
          font-size: 22px;
          line-height: 1;
          border-radius: 50%;
          background-color: #ffffff;
        }
      }
      .form-group {
        width: 7vw;
        height: 7vw;
        .form-label {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          color: #707070;
          font-size: 0.875rem;
          border: 1px dashed #707070;
          border-radius: 0.25rem;
          text-align: center;
          cursor: pointer;
          .icon {
            font-size: 1.125rem;
          }

          &:hover,
          &:focus {
            border: 1px solid #4f42ff;
          }
        }
        .form-control {
          display: none;
        }
      }
    }
  }

  &.matching-quest {
    .answer {
      padding: 2rem 0 0 0;
      .row {
        justify-content: space-between;
      }
      .choice-name {
        .icon {
          font-size: 2rem;
          width: 100%;
          text-align: center;
          display: inline-block;
          color: #8a8a8a;

          .icon-arrow {
            margin-top: -1rem;
          }
        }
      }
    }
  }
}

// Onload animations
.onload-anim {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);

  .anim-box {
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    left: 50%;
    display: flex;
    align-items: center;
  }

  .anim-text {
    padding: 1.25rem 2rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    font-size: 1.25rem;
    font-weight: 500;
    color: #070707;
  }
  .anim-icon {
    position: relative;
    right: 30px;
    color: #ffffff;
    font-size: 1.5rem;
    animation: mymove 1s infinite;
    animation-timing-function: ease-in;
  }
}

@keyframes mymove {
  from {
    right: 30px;
  }
  to {
    right: 50px;
  }
}
