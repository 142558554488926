.modal-dialog {
  --bs-modal-width: 700px;
}
// .modal-header {
//   --bs-modal-header-padding: 1rem 1rem;
// }
// .modal-body {
//   --bs-modal-padding: 1rem;
// }

.create-quest-modal,
.logout-modal,
.delete-modal {
  color: #070707;
  .modal-dialog {
    max-width: 700px;
    padding-bottom: 0;
  }
  .modal-header {
    justify-content: center;
    border-color: #ffffff;
    padding-top: 1rem;
    padding-bottom: 0;
    .modal-title {
      font-size: 2rem;
    }
  }
  .modal-body {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    // max-height: calc(85vh - 100px);
    // overflow: auto;
    // margin-right: 10px;
    .character-img {
      height: 35px;
      margin-left: 0.5rem;
    }
  }
  .modal-footer {
    border-color: #ffffff;
    padding: 0 3.5rem 2rem;
  }
}

// Logout modal
.logout-modal,
.delete-modal {
  .modal-dialog {
    max-width: 500px;
  }
  .modal-title {
    font-weight: 600;
  }
  .modal-body {
    p {
      text-align: center;
      font-size: 1.125rem;
      span {
        color: #4F42FF;
      }
    }
  }
  .modal-footer {
    justify-content: center;
    column-gap: 1.5rem;
  }
}


