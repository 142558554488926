.header {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #00000029;
  padding: 0.75rem 4rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  .org-logo {
    display: inline-flex;
    .org-logo-img {
      max-height: 26px;
    }
  }

  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
}

// Avatar
.avatar {
  width: 45px;
  height: 45px;
  background-color: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .avatar-img {
    max-width: 100%;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #ffffff;
  }
}
