@import "./abstracts/global";
@import "./base/global";
@import "./components/global";
@import "./layout/global";
@import "rwd.scss";

body {
  font-family: "DM Sans", sans-serif;
  background-color: #ececf0;
  overflow-x: hidden;
}
.app-container {
  width: 100%;
  height: 100vh;
}

.main-container {
  height: 100vh;
  padding: 4.5rem 4.5rem 2rem;
  &.bg-white {
    background-color: #ffffff !important;
  }

  &.create-quest {
    height: auto;
    padding-bottom: 0;
    // margin-bottom: 2rem;
  }
  &.assign-quest {
    // height: auto;
    // padding-bottom: 0;
  }
}

.container {
  max-width: 100%;
}

// custom scrollbar
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #9f9f9f;
}

// error-msg {
.error-msg {
  color: red;
}

.loader {
  position: absolute;
  width: 100%;
  height: calc(100% - 80px);
  top: 15px;
  left: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background-color: #ffffff;
  border-radius: 0.5rem;
}

//Main-Loader
.loader-block {
  display: flex;
  justify-content: center;
  min-height: inherit;
  height: inherit;
  align-items: center;
}

.dashboard-loader {
  position: absolute;
  width: 100%;
  height: calc(100% - 20px);
  top: 20px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background-color: #ffffff;
  border-radius: 0.5rem;
}
